import React, { useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { faShare } from '@fortawesome/pro-light-svg-icons';


const thisYear = new Date().getFullYear();
const exampleInputAge = 27 + Math.floor(Math.random() * (85 - 27));
const exampleInputYear = thisYear - exampleInputAge;
const exampleInputText = 'e.g. ' + exampleInputYear + ' or ' + exampleInputAge;

const App = () => {
    const [items, setItems] = useState([
        // { itemName: 'Alex', itemYear: 1991, itemAge: calculateAge(1991), itemCat: '?' },
    ]);

    const webAddress = 'https://masterscalc.easyregatta.co.uk';

    const [inputName, setInputName] = useState('');
    const [inputYear, setInputYear] = useState('');
    const [averageAge, setAverageAge] = useState('');
    const [overallMastersCat, setOverallMastersCat] = useState(null);
    const [shareTxt, setShareTxt] = useState('');
    const [shareClipboardTxt, setShareClipboardTxt] = useState(webAddress);
    // const nextYear = thisYear + 1;

    const ageBands = [
        { letter: 'A', minAge: 27},
        { letter: 'B', minAge: 36},
        { letter: 'C', minAge: 43},
        { letter: 'D', minAge: 50},
        { letter: 'E', minAge: 55},
        { letter: 'F', minAge: 60},
        { letter: 'G', minAge: 65},
        { letter: 'H', minAge: 70},
        { letter: 'I', minAge: 75},
        { letter: 'J', minAge: 80},
        { letter: 'K', minAge: 85},
    ];

    const handleAddButtonClick = () => {
        if (inputYear === '') {
            return
        }
        const selectedYear = yearOrAge(inputYear);
        const age = calculateAge(selectedYear);
        const band = calculateBand(age);
        const newItem = {
            itemName: inputName,
            itemYear: selectedYear,
            itemAge: age,
            itemCat: band,
        };

        const newItems = [...items, newItem];

        setItems(newItems);
        setInputName('');
        setInputYear('');
        calculateAverageAge(newItems);
    };

    const handleDeleteItem = (index) => {
        console.log(index);
        const newItems = [...items];
        newItems.splice(index, 1);

        setItems(newItems);
        calculateAverageAge(newItems);

        setInputName('');
        setInputYear('');
        // calculateAverageAge(newItems);
    };

    const calculateAverageAge = (theseItems) => {
        if (theseItems.length < 1) {
            setAverageAge('');
            setOverallMastersCat('');
            return;
        }
        const sumAges = theseItems.reduce((total, item) => {
            return total + item.itemAge;
        }, 0);
        console.log(sumAges);

        // round to 1 decimal
        const average = Math.round((sumAges / theseItems.length) * 10) / 10;
        const overallBand = calculateBand(average);

        setAverageAge(average);
        setOverallMastersCat(overallBand);
        createShareTxt(theseItems, average, overallBand);
    };

    const createShareTxt = (someItems, avAge, ovBand) => {
        var prepTxt = createBaseShareTxt(someItems, avAge, ovBand);
        prepTxt += webAddress;
        console.log(prepTxt);
        setShareTxt(prepTxt);
        setShareClipboardTxt(prepTxt);
    }

    const createBaseShareTxt = (someItems, avAge, ovBand) => {
        var prepTxt = "Crew for " + thisYear + "\n";
        for (var i = 0; i < someItems.length; i++) {
            prepTxt += someItems[i].itemName + " " + someItems[i].itemYear + " " + someItems[i].itemCat + "\n";
        }
        prepTxt += "\nCrew average age: " + avAge + "\n";
        prepTxt += "Band: " + ovBand + "\n\n";
        return prepTxt;
    }

    const yearOrAge = (numberInput) =>{
        var output = numberInput;
        if (numberInput < 200) {
            output = thisYear - numberInput;
        }
        return output;
    }

    const calculateAge = (year) =>{
        console.log(thisYear);
        const resultingAge = thisYear - year;
        if (resultingAge < 1) {
            return null;
        }
        return resultingAge;
    }

    const calculateBand = (age) =>{
        console.log(age);
        var bandOutput = 'n/a';
        for (var i = 0; i < ageBands.length; i++) {
            if (ageBands[i].minAge > age) {
                break;
            }
            bandOutput = ageBands[i].letter;
        }
        return bandOutput;
    }

    const handleCopied = () => {
        if (navigator.share) {
          navigator.share({
            title: 'MastersCalc - easyregatta',
            text: shareTxt,
            // url: webAddress,
          })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        } else {
            alert('Copied to clipboard!');
        }
    }

    return (
        <div className='container'>
                <h2>
                    Add your crew
                    <button type="button" className="btn btn-link" data-bs-toggle="modal" data-bs-target="#helpModal">
                        <FontAwesomeIcon icon={faCircleQuestion} />
                    </button>
                </h2>

            <div className="modal fade" id="helpModal" tabindex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="helpModalLabel">How to use</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <p>Welcome to the MastersCalc from easyregatta.</p>
                    <p>This is a free tool designed to help you work out the British Rowing Masters category of your crew.</p>
                    <p>
                        Add each person using a name and the year they were born / age.
                    </p>
                    <p>
                        The crews' average age and the resulting Masters category will be shown at the bottom.
                    </p>
                    <p>
                        You can copy the result to your clipboard or share with your crew via messenger apps using the 'share' button.
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
            <div className='main-table'>
                <form>
                  <div className='mb-3'>
                    <div id="formHelp" className="form-text">Enter each crew member's details.</div>
                    <label htmlFor='inputName' className='form-label'>Name</label>
                    <input id='inputName' value={inputName} onChange={(event) => setInputName(event.target.value)} className='form-control' placeholder='e.g. Bob' />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="inputYear" className="form-label">Year of Birth / Age on their Birthday this year</label>
                    <input id='inputYear' value={inputYear} onChange={(event) => setInputYear(event.target.value)} className='form-control' placeholder={exampleInputText} type="number" min="1900" max="3000"/>
                  </div>
                  <div className="mb-3">
                    <button type="button" className="btn btn-secondary" onClick={() => handleAddButtonClick()}>
                        <FontAwesomeIcon icon={faPlus}/>
                    </button>
                  </div>
                </form>
                <div className='add-item-box'>
                </div>
                <h4>
                    Crew for {thisYear}
                </h4>
                <table className='table'>
                  <thead>
                    <tr>
                      <th scope='col'>Name</th>
                      <th scope='col'>Born</th>
                      <th scope='col'>Age</th>
                      <th scope='col'>Masters Category</th>
                      <th scope='col'>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => (
                        <tr>
                            <td>
                                {item.itemName}
                            </td>
                            <td>
                                {item.itemYear}
                            </td>
                            <td>
                                {item.itemAge}
                            </td>
                            <td>
                                {item.itemCat}
                            </td>
                            <td>
                                <div className='quantity'>
                                    <button type="button" className="btn btn-secondary btn-sm" onClick={() => handleDeleteItem(index)} >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                  </tbody>
                </table>
                <div className='card'>
                  <div className='card-body'>
                    <p className='lead'>
                      Average age: <span className='badge rounded-pill bg-light'>{averageAge}</span>
                    </p>
                    <p className='lead'>
                      Crew Masters Category: <span className='badge rounded-pill bg-light'>{overallMastersCat}</span>
                    </p>
                  </div>
                </div>
                <hr />
                <div>
                    <CopyToClipboard text={shareClipboardTxt} onCopy={() => handleCopied()}>
                        <button id="share_button" type="button" className="btn btn-light">Share! <FontAwesomeIcon icon={faShare} /></button>
                    </CopyToClipboard>
                </div>
            </div>
        </div>
    );
};

export default App;